/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

import { ShopCS } from '../../content/assets/icons/makeBlocksComingSoon'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import BlogContainer from '../components/layout/blogContainer'

const ShopPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Shop"
        keywords={[`make kits`, `store`, `buy`, `price`, 'DIY']}
      />
      <BlogContainer>
        <div className="mx-auto mt-48 mb-32 h-64 w-64">
          <ShopCS />
        </div>
      </BlogContainer>
    </Layout>
  )
}

export default ShopPage

export const shopPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`
